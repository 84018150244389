import "./style.css"
import QuoteOfDay from "./QuoteOfDay";


function HomePage() {

      return (
        <div className="home-page">
          <QuoteOfDay/>
        </div>
      );
      
  }
  
  

export default HomePage;
